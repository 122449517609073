.footer {
    &-nav {
        background: #f8f8f8;
        padding: 10px 0;

        &-link {
            color: #2b2b2b;
            font-size: 10px;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}
