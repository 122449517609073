@keyframes fromDown {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@mixin animFromDown($i) {
    opacity: 0;
    animation-name: fromDown;
    animation-duration: 900ms;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-delay: 1200ms * $i + 500ms;
}

.main {
    margin-top: 60px;

    &-content {
        margin-bottom: 40px;
    }

    &-success {
        padding: 60px 0;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
        background: #c6ffb9;
        color: #257938;
        border-radius: 3px;
    }

    &-title {
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        @include animFromDown(0);
    }

    &-description {
        margin-bottom: 30px;
        max-width: 752px;
        text-align: center;
        font-size: 14px;
        @include animFromDown(0);

        &-part {
            display: block;
            margin-bottom: 11px;
        }
    }

    &-download {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        @include animFromDown(0);

        &-label {
            margin-right: 10px;
            font-weight: bold;
        }
    }

    &-image {
        width: 555px;
        height: 342px;
        border: solid 1px #efefef;
        margin-bottom: 40px;
        @include animFromDown(1);
    }

    &-working {
        @include animFromDown(2);
        margin-bottom: 40px;
        margin-top: 20px;

        &-title {
            display: block;
            text-align: center;
            font-size: 20px;
            margin-bottom: 11px;
        }

        &-subtitle {
            display: block;
            font-weight: 700;
        }
    }

    &-col {
        &-wrapper {
            @include animFromDown(2);
            margin-bottom: 40px;
        }
        &-icon {
            margin-bottom: 15px;
        }
        &-title {
            font-size: 16px;
        }
        &-description {
            font-size: 14px;
        }
    }

    &-separator {
        border: solid 1px #efefef;
        width: 100%;
    }

    &-bottom {
        margin-top: 50px;
        margin-bottom: 40px;
        font-size: 12px;

        &-title {
            font-size: 12px;
            display: block;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }
}
