@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./components/index.scss";

* {
    font-family: 'Open Sans', sans-serif;
}

html,
body {
    width : 100%;
    height: 100%;
}

img {
    max-width: 100%;
}