@import "../../../base/scss/bootstrap";

.header {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    &>.container {
        padding: 30px 0;
    }

    &>a {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    &-logo {
        width       : 42px;
        object-fit  : contain;
        margin-right: 15px;

        &-wrapper {
            display    : flex;
            align-items: center;
        }
    }

    &-title {
        font-family: 'Pattaya', sans-serif;
        font-size  : 15px;
        margin     : 0;
        color      : initial;
    }

    & .row {
        @include media-breakpoint-down(md) {
            margin        : 0;
            flex-direction: column-reverse;
        }

        &.row-center {
            align-items: center;
        }
    }

    &-search {
        width: fit-content;

        @include media-breakpoint-down(md) {
            margin-left  : 0;
            margin-bottom: 13px;
        }
    }

    &-download {
        @include media-breakpoint-down(md) {
            align-self: flex-end;
        }

        &-icon {
            margin-right: 10px;
        }
    }
}