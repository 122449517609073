.button {
    background: #1a73e8;
    border: solid 2px #1a73e8;
    border-radius: 32px;
    color: #fff;
    fill: #fff;

    transition: background 300ms ease-out,
                border 300ms ease-out,
                fill 300ms ease-out,
                color 300ms ease-out;

    &:hover {
        background: #fff;
        border: solid 2px #1a73e8;
        color: #1a73e8;
        fill: #1a73e8;
    }
}

.button-size-1 {
    padding: 14px 38px;
    font-size: 16px;
}

.button-size-2 {
    padding: 11px 33px;
    font-size: 12px;
}
