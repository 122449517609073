.search {

    position: relative;

    &-content {
        min-width: 300px;
        color: #333;
        font-size: 14px;
        padding: 10px 40px 10px 20px;
        border: solid 1px #efefef;

        &::placeholder {
            color: #afafaf;
        }

        &:focus {
            outline: none;
        }
    }

    &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        right: 12px;
        background: none;
        border: none;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: background 300ms ease-out;
        &:hover {
            background: #efefef;
        }
    }

    &-icon {
        width: 90%;
        height: 90%;
        object-fit: contain;
    }
}
